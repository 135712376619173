export const type = [
    {
        label: '优质房源-新房',
        value: 1
    },
    {
        label: '优质房源-二手房',
        value: 2
    },
    {
        label: '优质房源-办公租赁',
        value: 3
    },
    {
        label: '发布房源',
        value: 4
    },
    {
        label: '经纪人入口',
        value: 5
    },
    {
        label: '地图找房',
        value: 6
    },
    {
        label: '帮我找房',
        value: 7
    },
    {
        label: '我的发布',
        value: 8
    },
    {
        label: '新房流程',
        value: 9
    },
    {
        label: '线上签约',
        value: 10
    },
    {
        label: '我的费用',
        value: 11
    },
    {
        label: '交易进程',
        value: 12
    },
    {
        label: '我要看房',
        value: 13
    },
    {
        label: '蜜豆商城',
        value: 14
    },
    {
        label: '招募经纪人',
        value: 15
    }
]