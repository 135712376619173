import {get, post, Delete, put} from "@/api/axios";

export const list = params => get('/web/base/vajra/list', params);

export const add = params => post('/web/base/vajra/add', params);

export const deleteNew = params => Delete('/web/base/vajra/delete/' + params.id, '');

export const view = params => get('/web/base/vajra/view/' + params.id, '');

export const update = params => put('/web/base/vajra/update', params);